<template>
  <div class="pc">
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.pc {
  width: 100%;
}
</style>
